<template>
    <article>
        <h1>蓝灯鱼用户协议</h1>
        <h2>第一章 总则</h2>
        <h2>1.1 协议的接受</h2>
        <p>欢迎使用北京蓝灯鱼智能科技有限公司（以下简称“蓝灯鱼”）提供的服务。在您注册成为蓝灯鱼用户之前，请仔细阅读本协议的所有条款。您在注册过程中点击“勾选”确认，即表示您已充分阅读、理解并同意接受本协议的条款，并承诺遵守。如您不同意相关变更，应立即停止使用服务。继续使用视为接受修订后的条款。本协议对您和蓝灯鱼均具有法律约束力。</p>
        <p>本协议适用于您使用蓝灯鱼网站（域名为：<b style="  font-style: italic;">lanternfish.cn</b>）及通过该平台提供的线上服务，包括但不限于：蓝灯鱼专利 <b>OA</b>智能答复（<b>OA</b>精灵）、蓝灯鱼专利新申请智能撰写（芯智写）、蓝灯鱼商标驳通智写、蓝灯鱼商标异议智写、蓝灯鱼商标<b>AI</b>图文查询、蓝灯鱼专利<b>AI</b>翻译、蓝灯鱼专利效率工具、蓝灯鱼中小微企业托管平台等（以下统称“服务”）。</p>
        <p>蓝灯鱼平台致力于为用户提供高效、便捷的知识产权相关服务，涵盖专利、商标等多个领域，通过智能化手段提升用户体验和服务质量。我们深知一份清晰、全面的服务协议对于保障用户权益和规范平台运营的重要性，因此精心制定了本协议，力求在用户与平台之间建立起透明、公平、互信的合作关系。</p>
        <h2>1.2 协议的变更</h2>
        <p>蓝灯鱼有权根据需要随时修改或更新本协议，并将最新版本发布在网站上。您在使用蓝灯鱼服务时，应定期查看本协议的最新版本。如果您不同意修改后的条款，应立即停止使用蓝灯鱼的服务。继续使用则视为接受修改后的协议。</p>
        <p>互联网行业瞬息万变，法律法规、市场环境和技术发展等因素都可能影响平台的运营和服务内容。为了适应这些变化，不断优化服务，提升用户体验，蓝灯鱼保留对协议进行调整的权利。我们承诺在变更协议时，会通过合理的方式通知用户，如在首页显著位置发布公告等。同时，我们也鼓励用户定期关注协议更新情况，以便及时了解自身权利和义务的变化。</p>
        <h2>第二章 用户资格</h2>
        <h2>2.1 用户条件</h2>
        <p>使用蓝灯鱼服务的用户应具备完全民事权利能力和完全民事行为能力，能够独立承担法律责任的自然人、法人或其他组织。如果您不具备上述资格，请勿注册或使用蓝灯鱼的服务。如发现不符合条件的用户使用服务，蓝灯鱼有权注销其账户，并追究由此产生的一切法律责任。</p>
        <p>知识产权服务涉及复杂的法律和专业问题，需要用户具备相应的认知和判断能力。为了确保用户能够正确理解和使用平台服务，保障交易的安全和合法性，我们对用户资格进行了明确限定。这是对用户负责，也是对平台和其他合法用户负责的体现。若未成年人或限制民事行为能力人未经监护人同意擅自注册使用，由此产生的一切后果由其本人及监护人承担。</p>
        <h2>2.2 信息真实准确</h2>
        <p>用户在注册时应提供真实、准确、完整的个人信息，并在信息变更时及时更新。提供虚假信息或未及时更新信息可能导致账户被限制或注销，并承担由此产生的一切后果。</p>
        <p>准确的用户信息是平台提供个性化服务、确保沟通顺畅以及处理可能的争议的基础。例如，在账户安全验证、服务通知推送、权益保护等环节，都需要依赖用户提供的联系方式等信息。因此，我们强烈建议用户如实填写注册信息，并在相关信息发生变化时，及时在个人账户设置中进行更新。</p>
        <h2>2.3 用户承诺</h2>
        <p>用户承诺遵守中国法律法规、社会公序良俗及平台规则，不得利用服务从事违法或损害他人权益的行为。</p>
        <h2>第三章 用户权利与义务</h2>
        <h2>3.1 账户安全</h2>
        <p>用户应妥善保管自己的账户和密码，对账户下发生的所有活动承担全部责任。如因用户自身原因导致账户信息泄露或被盗用，应及时通知蓝灯鱼客服，并采取措施防止损失扩大。蓝灯鱼不承担因用户疏忽导致的账户安全问题所产生的任何责任。</p>
        <p>账户是用户访问平台服务的钥匙，也是平台识别用户身份的重要依据。一旦账户被盗用，不仅用户的个人隐私和财产可能受到威胁，还可能引发一系列的安全问题，如非法操作、数据泄露等。因此，用户在设置密码时应选择强度较高的组合，避免使用过于简单或容易被猜到的密码。同时，不要在不安全的设备或网络环境下登录账户，定期更换密码也是增强账户安全性的有效措施。</p>
        <h2>3.2 合法使用</h2>
        <p>用户应遵守法律法规，不得利用蓝灯鱼平台从事任何违法活动或侵犯他人合法权益的行为。包括但不限于发布虚假信息、侵权内容、进行不正当竞争等。如因用户行为导致蓝灯鱼遭受任何损失或第三方索赔，用户应承担全部赔偿责任。</p>
        <p>蓝灯鱼平台作为知识产权服务的提供者，一直致力于维护良好的网络环境和市场秩序。用户在使用平台时，必须严格遵守国家相关法律法规，遵循诚实信用原则。例如，在发布专利申请信息时，不得抄袭他人的技术方案；在商标查询过程中，不得恶意抢注他人知名商标等。对于违反法律法规和平台规则的行为，蓝灯鱼将采取必要的措施进行处理，包括但不限于警告、限制功能、封禁账户等，同时保留追究法律责任的权利。</p>
        <p>用户在蓝灯鱼注册的账号名称，不得有下列情形：</p>
      <ul>
            <li>1、违反宪法或法律法规规定的；</li>
            <li>2、危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；
            </li>
            <li>3、损害国家荣誉和利益的，损害公共利益的；
            </li>
            <li>4、煽动民族仇恨、民族歧视，破坏民族团结的；
            </li>
            <li>5、破坏国家宗教政策，宣扬邪教和封建迷信的；
            </li>
            <li>6、散布谣言，扰乱社会秩序，破坏社会稳定的；
            </li>
            <li>
              7、散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；
            </li>
            <li>8、侮辱或者诽谤他人，侵害他人合法权益的；
            </li>
            <li>9、含有法律、行政法规禁止的其他内容的。
            </li>
        </ul>
        <p>用户不得冒用、关联机构或社会名人注册账号名称；商业信息泄露。如因委托方故意或者过失导致的账户冒用而引发的责任和相关损失，由委托方承担。因此给本站带来的损害，委托人应与实际使用人承担连带责任。</p>
        <p>用户不得以任何形式擅自转让或授权他人使用自己在蓝灯鱼的用户帐号；</p>
        <p>用户在蓝灯鱼上发布需求及相关服务发布信息，不得违反国家法律、法规、行政规章的规定、不得侵犯他人知识产权或其他合法权益的信息、不得违背社会公共利益或公共道德、不得违反蓝灯鱼的相关规定；
        </p>
        <p>用户在蓝灯鱼交易中应当遵守诚实信用原则，不得以干预或操纵发布需求等不正当竞争方式扰乱网上交易秩序，不得从事与网上交易无关的不当行为；</p>
        <p>用户承诺自己在使用蓝灯鱼实施的所有行为遵守法律、法规、行政规章和蓝灯鱼的相关规定以及各种社会公共利益或公共道德。如有违反导致任何法律后果的发生，用户将以自己的名义独立承担相应的法律责任；
        </p>
        <p>未经蓝灯鱼书面允许，用户不得将蓝灯鱼的任何资料以及在交易平台上所展示的任何信息作商业性利用（包括但不限于以复制、修改、翻译等形式制作衍生作品、分发或公开展示）；
          用户不得使用以下方式登录“蓝灯鱼”网站或破坏网站所提供的服务：</p>
      <ul class="active">
        <li>1、以任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件或其它自动方式访问或登录蓝灯鱼；</li>
        <li>2、通过任何方式对本公司内部结构造成或可能造成不合理或不合比例的重大负荷的行为；</li>
        <li>3、通过任何方式干扰或试图干扰网站的正常工作或网站上进行的任何活动。</li>
      </ul>
        <p>用户同意接收来自蓝灯鱼的信息，包括但不限于活动信息、交易信息、促销信息等。
        </p>
      <h2>3.3 信息真实准确</h2>
        <p>用户在平台上发布的信息应真实、准确、完整，不得误导他人。对于发布的任何需求、咨询或其他信息，用户应确保其合法性和真实性，并承担由此产生的一切法律责任。</p>
        <p>在知识产权服务中，信息的真实性直接关系到服务的质量和结果。例如，用户在提交专利OA答复时，提供的技术资料必须真实可靠，以便平台能够准确理解和处理相关问题。若因用户提供的信息虚假或不完整，导致服务出现偏差或失败，平台不承担任何责任，由此引发的法律纠纷和经济损失由用户自行承担。</p>
        <h2>3.4 知识产权尊重</h2>
      <p>用户应尊重蓝灯鱼及其他用户的知识产权，不得侵犯他人的著作权、商标权、专利权等合法权益。未经权利人许可，不得擅自使用、复制、修改、传播或以其他方式处置他人作品或信息。
        </p>
        <p>知识产权是创新的核心驱动力，保护知识产权是每个用户应尽的义务。在蓝灯鱼平台上，用户可能会接触到大量的专利文献、商标图案、技术文章等信息，这些都是权利人的心血结晶，受到法律的严格保护。用户在使用这些信息时，必须获得权利人的明确授权，并按照约定的方式和范围使用。否则，将构成侵权行为，需承担相应的法律责任。
        </p>
      <h2>第四章 蓝灯鱼的权利与义务</h2>
      <h2>4.1 服务提供 </h2>
      <p>蓝灯鱼将尽力提供稳定、安全的服务，包括但不限于专利OA智能答复、专利新申请智能撰写、商标驳通智写、商标异议智写、商标AI图文查询、专利AI翻译、专利效率工具、中小微企业托管平台等。但蓝灯鱼不保证服务的绝对无误、及时性或不受干扰。</p>
        <p>蓝灯鱼拥有一支专业的技术团队和知识产权专家，不断优化平台功能和服务流程，以满足用户日益增长的需求。我们采用先进的技术手段和严格的质量控制体系，确保服务的准确性和可靠性。然而，由于互联网服务的特殊性，可能会受到网络拥堵、系统故障、黑客攻击等不可预见因素的影响，导致服务暂时中断或出现异常。在这种情况下，蓝灯鱼将尽快采取措施恢复服务，并及时通知用户。</p>
        <h2>4.2 信息审核</h2>
      <p>蓝灯鱼有权对用户注册信息、发布内容等进行审核，如发现存在违法、违规或违反本协议的情况，有权要求用户修改或直接删除相关内容，情节严重的可限制或终止用户账户的使用。</p>
      <p>为了维护平台的良好生态和正常运营秩序，蓝灯鱼建立了完善的审核机制。审核团队将对用户提交的信息进行严格审查，确保其符合法律法规和平台规则。审核过程可能包括自动筛选和人工复查相结合的方式，以提高效率和准确性。如果用户发布的信息涉嫌违法违规、侵权、虚假宣传等内容，平台将依据情节轻重采取相应的处理措施，包括但不限于警告、删除信息、限制账户功能、封禁账户等。</p>
      <h2>4.3 隐私保护</h2>
      <p>蓝灯鱼将采取合理措施保护用户的个人信息安全，不向第三方出售或泄露用户的个人或法人信息，除非事先获得用户同意或依据法律要求。</p>
      <p>用户隐私是蓝灯鱼高度重视的问题，我们采用了多种技术和管理措施来保障用户信息安全。例如，数据加密技术可防止用户信息在传输过程中被窃取；严格的内部权限管理，确保只有授权的工作人员才能访问相关数据；定期的安全审计和漏洞扫描，及时发现和修复潜在的安全风险等。同时，蓝灯鱼承诺不会将用户信息用于商业目的的出售或交换，除非法律明确要求或用户事先同意。</p>
      <h2>4.4 知识产权维护</h2>
      <p>蓝灯鱼对平台上的所有内容（包括但不限于文字、图片、软件、界面设计等）享有知识产权，未经蓝灯鱼书面许可，用户不得以任何方式复制、修改、传播或用于商业目的。</p>
      <p>蓝灯鱼平台在开发和运营过程中，投入了大量的资源和创新努力，形成了独特的技术成果和品牌标识等知识产权。这些知识产权是平台的核心竞争力和宝贵资产，受到法律的严格保护。用户在使用平台时，应充分尊重蓝灯鱼的知识产权，不得进行任何侵犯行为。例如，不得对平台的软件进行反编译、破解；不得抄袭平台的设计元素用于自己的商业项目等。如需使用平台的相关知识产权内容，必须事先获得蓝灯鱼的书面授权，并按照授权范围和方式使用。</p>
      <h2>第五章 隐私政策</h2>
      <h2>5.1 信息收集</h2>
      <p>蓝灯鱼在用户注册、使用服务过程中可能会收集用户的个人信息，包括但不限于姓名、身份证号、联系电话、邮箱等，以便提供更好的服务和用户体验。为了向用户提供个性化、精准化的服务，以及不断优化平台功能，蓝灯鱼需要收集一些必要的用户信息。这些信息的收集是经过用户明确授权的，在注册过程中，用户会被告知需要提供哪些信息以及这些信息的用途。例如，收集姓名和身份证号是为了进行实名认证，确保账户的安全性和合法性；收集联系电话和邮箱是为了方便与用户取得联系，及时发送服务通知和重要信息等。</p>
      <h2>5.2 信息使用</h2>
      <p> <b>收集的用户信息将仅用于提供和改进蓝灯鱼的服务，不会用于其他目的。蓝灯鱼可能会利用用户信息进行内部统计、分析，以优化服务质量和功能。用户信息在蓝灯鱼内部将被严格保密，仅用于提升服务质量的必要场景。例如，通过分析用户的使用习惯和偏好，平台可以针对性地优化服务流程，推出更符合用户需求的功能模块。同时，这些信息也会用于安全防护方面，如监测异常登录行为、防范账户被盗用等。在使用用户信息进行统计分析时，会采取匿名化处理，确保无法识别出具体的个人身份。</b></p>
      <h2>5.3 信息披露</h2>
      <p>在以下情况下，蓝灯鱼可能会披露用户的个人信息：</p>
      <ul>
            <li>• 经用户事先同意或授权；
            </li>
            <li>• 根据法律法规要求或行政机关、司法机关的命令；
            </li>
            <li>
              • 为维护蓝灯鱼的合法权益，如防止欺诈、追究法律责任等；
            </li>
        <li>• 在涉及合并、收购、资产转让等情况下，可能会将用户信息转移给相关方，但会确保信息的保密性和安全性。</li>
        </ul>
        <p>蓝灯鱼深知用户信息的敏感性，因此在信息披露方面采取了极为谨慎的态度。除上述情况外，不会向任何第三方披露用户的个人信息。在获得用户同意的情况下披露信息时，会明确告知用户披露的目的、范围和对象等详细信息，确保用户在充分知情的前提下做出自主决定。在法律要求的情况下披露信息时，会严格依据法律程序进行，积极配合行政机关和司法机关的调查工作，同时也会尽力保障用户的合法权益不受侵害。
        </p>
      <h2>第六章 责任限制</h2>
        <h2>6.1 技术问题免责
        </h2>
        <p>络故障、第三方行为等原因导致的用户损失，蓝灯鱼不承担赔偿责任。尽管蓝灯鱼尽力确保平台的稳定运行，但互联网环境存在诸多不可控因素。例如，不可抗力事件如自然灾害、政府行为等可能导致服务器瘫痪；网络故障如光纤中断、运营商故障等可能使用户无法正常访问平台；第三方行为如黑客攻击、恶意软件入侵等可能破坏平台数据和功能。在这些情况下，蓝灯鱼将迅速采取应急措施，尽力减少损失和影响，但无法对因此给用户造成的损失承担赔偿责任。
        </p>
        <h2>6.2 内容准确性免责</h2>
        <p>
          蓝灯鱼提供的信息仅供参考，用户应自行判断和核实信息的准确性、完整性。对于因使用平台信息而产生的任何决策或后果，蓝灯鱼不承担责任。蓝灯鱼平台上的信息来源广泛，包括用户提交、公开数据整合、第三方合作等。尽管我们对信息进行了审核和整理，但由于信息本身的复杂性和动态性，无法保证所有信息都绝对准确无误。用户在使用平台信息时，应结合自身专业知识和实际情况进行综合判断。例如，在参考专利文献时，应结合相关法律法规和技术背景进行深入分析；在查询商标信息时，应注意商标的状态变化和法律效力等。如果用户因依赖平台信息而做出错误决策，导致自身权益受损，平台不承担相应责任。
        </p>
        <h2>
          6.3 第三方服务免责
        </h2>
        <p>蓝灯鱼可能会链接到其他第三方网站或提供第三方服务，但不对这些网站或服务的内容、质量、安全性负责。用户使用第三方服务时应自行承担风险。为了丰富用户的选择和体验，蓝灯鱼平台可能会提供一些第三方服务的链接或接口。这些第三方服务由独立的运营商提供，其内容、质量、安全性和隐私政策等均由第三方自行负责。用户在使用第三方服务时，应仔细阅读其服务条款和隐私政策，了解可能存在的风险。例如，第三方支付平台可能存在支付安全风险；第三方软件下载可能存在恶意软件风险等。蓝灯鱼对用户因使用第三方服务而遭受的任何损失不承担赔偿责任。</p>
        <h2>第七章 服务的变更与终止</h2>
        <h2>7.1 服务变更</h2>
        <p>蓝灯鱼有权根据业务发展需要，对提供的服务内容、功能、规则等进行调整或更新，无需事先通知用户。如用户不同意变更后的服务，可选择停止使用。随着技术的进步和市场需求的变化，蓝灯鱼需要不断优化和升级服务，以保持竞争力和满足用户的多样化需求。例如，可能会新增某些知识产权服务功能，或者对现有服务的流程进行改进。这些变更通常是为了提升用户体验和服务质量，但也可能会影响到用户的使用习惯。因此，我们建议用户定期关注平台公告和服务说明，及时了解服务变更情况。如果用户对变更后的服务有异议，可以选择停止使用相关服务，但在停止使用前已产生的权利义务关系仍需履行。</p>
        <h2>7.2 账户终止</h2>
      <p>用户可随时申请注销账户，但需经蓝灯鱼审核同意。账户终止后，用户在平台上的所有数据和信息将被清除，相关权利和义务即告终止。如用户违反本协议或法律法规，蓝灯鱼有权单方面终止用户账户，限制其使用服务，并追究相应责任。但该用户在被蓝灯鱼终止提供服务后，再一次直接或间接或以他人名义注册为蓝灯鱼用户的，蓝灯鱼有权再次单方面终止为该用户提供服务。</p>
      <p>账户注销是用户结束与蓝灯鱼服务关系的一种方式，但为了确保账户安全和合法权益，注销申请需要经过平台审核。</p>
      <p>审核过程中，平台会检查用户是否存在未结清的费用、未完成的订单或其他需要处理的事项。如果用户账户存在违法违规行为或违反协议的情况，平台有权拒绝注销申请，直至相关问题得到解决。</p>
      <p>一旦账户注销成功，用户将无法再登录和使用蓝灯鱼的服务，其在平台上的所有数据也将被删除，因此用户在申请注销前应备份重要的数据和信息。</p>
      <h2>第八章 知识产权</h2>
      <h2>8.1 平台知识产权</h2>
      <p>蓝灯鱼平台上的所有内容，包括但不限于软件、技术、程序、数据、文字、图像等，其知识产权均归蓝灯鱼所有或已获得合法授权。未经蓝灯鱼书面许可，任何单位或个人不得以任何方式擅自使用、复制、传播、展示或用于商业目的。</p>
      <p>蓝灯鱼在知识产权保护方面采取了严格的措施，不仅尊重他人的知识产权，也积极维护自身的知识产权权益。平台的软件系统、技术算法、界面设计、商标标识等都凝聚了蓝灯鱼团队的智慧和创新成果，受到著作权法、商标法、专利法等法律法规的保护。任何未经授权的使用或侵权行为，都将受到法律的追究。我们鼓励用户在合法合规的前提下，合理使用平台的服务和资源，共同营造良好的知识产权生态环境。</p>
      <h2>8.2 用户内容授权</h2>
      <p>用户在蓝灯鱼平台上发布的内容，视为授权蓝灯鱼在平台上展示、使用、传播等，但该授权不意味着蓝灯鱼获得内容的所有权。用户应确保发布内容的合法性，避免侵犯他人权益。</p>
      <p>用户在平台上发布的内容，如专利技术方案、商标设计图案、知识产权咨询问题等，都是用户自身创作或合法拥有的成果。</p>
      <p>用户通过发布这些内容，授权蓝灯鱼在平台范围内进行展示和传播，以便为用户提供相应的服务，如技术交流、需求匹配、服务推荐等。蓝灯鱼尊重用户对内容的所有权，在使用用户内容时，会遵循用户的授权范围和平台规则。同时，用户也应确保所发布内容不侵犯他人的知识产权，否则将承担由此引发的法律责任。</p>
      <h2>第九章 争议解决</h2>
      <h2>9.1 适用法律</h2>
      <p>本协议的订立、执行和解释均适用中华人民共和国法律。如协议条款与法律法规相抵触，以法律法规为准。蓝灯鱼作为在中国注册运营的企业，其服务和运营均受中国法律的管辖和保护。因此，本协议的各个方面都依据中国法律制定和执行。在协议履行过程中，如遇到法律未明确规定的事项，双方应依据诚实信用原则和商业惯例进行友好协商解决。如果协议中的某些条款与现行法律法规存在冲突，将以法律法规为准，双方应共同对相关条款进行调整和完善，以确保协议的合法性和有效性。</p>
      <h2>9.2 争议解决方式</h2>
      <p>因本协议引起的或与本协议有关的任何争议，双方应首先友好协商解决；协商不成的，任何一方均有权向蓝灯鱼所在地有管辖权的人民法院提起诉讼。在争议解决过程中，双方应秉持平等、诚信的态度，积极沟通，寻求互利共赢的解决方案。协商解决不仅能够节省时间和成本，还有助于维护双方的合作关系和商业信誉。如果协商无法达成一致，通过诉讼途径解决争议时，双方应尊重司法程序和法院判决，积极配合司法机关的调查和审理工作，以保障争议得到公正、合理的解决。</p>
      <h2>第十章 其他</h2>
      <h2>10.1 协议解释权</h2>
      <p>灯鱼将根据实际情况和用户反馈，对协议进行必要的解释和说明，以帮助用户更好地理解和遵守协议条款。同时，我们也欢迎用户对协议提出合理的意见和建议，以便我们不断完善协议内容，提高服务质量和用户体验。</p>
      <h2>10.2 通知方式</h2>
      <p>蓝灯鱼可通过网站公告、公众号、视频号、电子邮件、短信等方式向用户发送通知，通知自发出之日起视为已送达用户。</p>
      <p>为了确保用户能够及时接收重要通知，蓝灯鱼采用了多种通知方式。</p>
      <p>网站公告是面向所有用户的重要信息发布渠道，用户在访问平台时应留意公告栏的内容；电子邮件和短信则用于向特定用户发送个性化通知，如账户安全提醒、服务进度更新等。用户在注册时提供的联系方式应保持畅通和准确，如有变更应及时更新，以免错过重要信息。</p>
      <h2>10.3 协议生效</h2>
      <p>本协议自用户注册成为蓝灯鱼用户之日起生效，直至用户账户终止或本协议被合法解除。协议的生效标志着用户与蓝灯鱼之间正式建立了服务关系，双方的权利和义务开始受到协议的约束。在协议生效期间，用户可以充分享受蓝灯鱼提供的各项服务，同时也应履行相应的义务，如遵守平台规则、支付服务费用等。当用户账户终止或协议被合法解除时，协议的效力即告终止，但在此之前双方已产生的权利义务关系仍需继续履行完毕。</p>
      <div style="text-align: right;margin-top: 30px">
        <p style="margin: 10px 0;text-align: end">北京蓝灯鱼智能科技有限公司</p>
        <p style="margin: 10px 0;text-align: end"> <b style="  font-style: italic;">www.lanternfish.cn</b></p>
        <p style="margin: 10px 0;text-align: end">更新日期：<b>2025</b>年<b>3</b>月<b>18</b>日</p>
      </div>
    </article>
</template>

<script>
export default {

}
</script>

<style lang='less' scoped>
article {
	box-sizing: border-box;
	h1 {
		text-align: center;
		/*font-weight: 400;*/
		color: #022144;
	}
	p {
    text-align: justify;
		color: #565d64;
		line-height: 1.6;
	}
	h2 {
    margin: 10px 0;
		color: #022144;
		/*font-size: 1.2rem;*/
	}
	ul {
    &.active {
      text-indent: 2em; /* 缩进 2 个字符（通常是 2 个汉字宽度） */
    }
		list-style: decimal;
		color: #656e77;
    li {
      text-align: justify;
    }
	}
	padding-bottom: 20px;
}
</style>
